import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

const months = [
  'leden',
  'únor',
  'březen',
  'duben',
  'květen',
  'červen',
  'červenec',
  'srpen',
  'září',
  'říjen',
  'listopad',
  'prosinec',
];
const monthsShort = [
  'led',
  'úno',
  'bře',
  'dub',
  'kvě',
  'čvn',
  'čvc',
  'srp',
  'zář',
  'říj',
  'lis',
  'pro',
];

function plural(n: any) {
  return n > 1 && n < 5 && ~~(n / 10) !== 1;
}

function translate(num: any, withoutSuffix: any, key: any, isFuture: any) {
  switch (key) {
    case 's': // a few seconds / in a few seconds / a few seconds ago
      return withoutSuffix || isFuture ? 'pár sekund' : 'pár sekundami';
    case 'm': // a minute / in a minute / a minute ago
      return withoutSuffix ? 'minuta' : isFuture ? 'minutu' : 'minutou';
    case 'mm': // 9 minutes / in 9 minutes / 9 minutes ago
      if (withoutSuffix || isFuture) {
        return `${num} ${plural(num) ? 'minuty' : 'minut'}`;
      } else {
        return `${num} minutami`;
      }
      break;
    case 'h': // an hour / in an hour / an hour ago
      return withoutSuffix ? 'hodina' : isFuture ? 'hodinu' : 'hodinou';
    case 'hh': // 9 hours / in 9 hours / 9 hours ago
      if (withoutSuffix || isFuture) {
        return `${num} ${plural(num) ? 'hodiny' : 'hodin'}`;
      } else {
        return `${num} hodinami`;
      }
      break;
    case 'd': // a day / in a day / a day ago
      return withoutSuffix || isFuture ? 'den' : 'dnem';
    case 'dd': // 9 days / in 9 days / 9 days ago
      if (withoutSuffix || isFuture) {
        return `${num} ${plural(num) ? 'dny' : 'dní'}`;
      } else {
        return `${num} dny`;
      }
      break;
    case 'M': // a month / in a month / a month ago
      return withoutSuffix || isFuture ? 'měsíc' : 'měsícem';
    case 'MM': // 9 months / in 9 months / 9 months ago
      if (withoutSuffix || isFuture) {
        return `${num} ${plural(num) ? 'měsíce' : 'měsíců'}`;
      } else {
        return `${num} měsíci`;
      }
      break;
    case 'y': // a year / in a year / a year ago
      return withoutSuffix || isFuture ? 'rok' : 'rokem';
    case 'yy': // 9 years / in 9 years / 9 years ago
      if (withoutSuffix || isFuture) {
        return `${num} ${plural(num) ? 'roky' : 'let'}`;
      } else {
        return `${num} lety`;
      }
    default:
      return '';
  }
}

moment.locale(window.locale, {
  months,
  monthsShort,
  monthsParse: (function (pMonths, pMonthsShort) {
    let i;
    const monthsParse = [];

    for (i = 0; i < 12; i++) {
      // use custom parser to solve problem with July (červenec)
      monthsParse[i] = new RegExp(`^${pMonths[i]}$|^${pMonthsShort[i]}$`, 'i');
    }

    return monthsParse;
  })(months, monthsShort),
  weekdays: [
    'neděle',
    'pondělí',
    'úterý',
    'středa',
    'čtvrtek',
    'pátek',
    'sobota',
  ],
  weekdaysShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  weekdaysMin: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'D. MMMM YYYY H:mm',
    LLLL: 'D. MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'D.M.RRRR',
    LT: 'H:mm',
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[dnes v] LT',
    nextDay: '[zítra v] LT',
    nextWeek() {
      switch (this.day()) {
        case 0:
          return '[v neděli v] LT';
        case 1:
        case 2:
          return '[v] dddd [v] LT';
        case 3:
          return '[ve středu v] LT';
        case 4:
          return '[ve čtvrtek v] LT';
        case 5:
          return '[v pátek v] LT';
        case 6:
          return '[v sobotu v] LT';
        default:
          return '';
      }
    },
    lastDay: '[včera v] LT',
    lastWeek() {
      switch (this.day()) {
        case 0:
          return '[poslední neděli v] LT';
        case 1:
        case 2:
          return '[poslední] dddd [v] LT';
        case 3:
          return '[poslední středu v] LT';
        case 4:
        case 5:
          return '[poslední] dddd [v] LT';
        case 6:
          return '[poslední sobotu v] LT';
        default:
          return '';
      }
    },
    sameYear: '[v] llll [v] LT',
    sameElse: '[v] ll [v] LT',
  }),
  relativeTime: {
    future: 'za %s',
    past: 'před %s',
    s: translate,
    m: translate,
    mm: translate,
    h: translate,
    hh: translate,
    d: translate,
    dd: translate,
    M: translate,
    MM: translate,
    y: translate,
    yy: translate,
  },
  ordinalParse: /\d{1,2}\./,
  ordinal: () => '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});
